<template>
  <div class="text-center">
    <Row class="p-b-5">
      <i-col span="24">
        <Badge :count="taskitemFiles&&taskitemFiles.length?taskitemFiles.length:0">
          <div
            @click="handleChangeImgType(1)"
            :class="selectedType === 1? 'workplatform-radio-button-active': 'workplatform-radio-button'"
            class="p-l-20 p-r-20"
          >
            任务素材
          </div>
        </Badge>
        <Badge :count="taskitemAttachs&&taskitemAttachs.length?taskitemAttachs.length:0">
          <div
            @click="handleChangeImgType(2)"
            :class="selectedType === 2? 'workplatform-radio-button-active': 'workplatform-radio-button'"
            class="p-l-20 p-r-20"
          >
            反馈照片
          </div>
        </Badge>

      </i-col>
    </Row>
    <div
      class="m-t-5 m-b-5 text-right"
      v-if="selectedType===2&&taskitemAttachs.length"
    >
      <Button
        type="success"
        size="small"
        @click="viewBigImage"
      >查看原图</Button>
    </div>
    <img-light-box
      ref="imgLightBox"
      :imgIndex.sync="imageIndex"
    />
  </div>
</template>

<script>
import ImgLightBox from '@/components/common/ImgVideoLightBox'
export default {
  props: {
    taskitemFiles: [Array],
    taskitemAttachs: [Array]
  },
  components: { ImgLightBox },
  data () {
    return {
      selectedType: 1,
      imageIndex: 0
    }
  },
  methods: {
    handleChangeImgType (type) {
      this.selectedType = type
      const postData = {
        startIndex: 0,
        imgList: []
      }

      if (type === 1) {
        postData.imgList = this.taskitemFiles
      } else {
        postData.imgList = this.taskitemAttachs
      }
      this.$refs.imgLightBox.init(postData)
    },
    viewBigImage () {
      this.$refs.imgLightBox.viewBigImg()
    }
  },
  watch: {
    taskitemFiles: {
      deep: true,
      immediate: true,
      handler (val) {
        if (val && val.length) {
          this.selectedType = 1
          this.handleChangeImgType(1)
        }
      }
    }
  }

}
</script>
